export const optionsChatGPT = Object.freeze({
  model: "gpt-3.5-turbo",
  temperature: 1,
  max_tokens: 3000,
  top_p: 1,
  frequency_penalty: 0.0,
  presence_penalty: 0.0,
  stop: ["/"],
});

export const optionsStoryGPT = Object.freeze({
  model: "gpt-3.5-turbo",
  messages: [
    {
      role: "system",
      content:
        "You are a Dungeon Scribe helping Dungeons and Dragons players create their world and characters.",
    },
  ],
  temperature: 0.7,
  max_tokens: 2000,
  top_p: 1,
  frequency_penalty: 0.0,
  presence_penalty: 0.0,
  stop: ["\n", "Testing:"],
});

export const types = Object.freeze({
  CAMPAIGN: "CAMPAIGN",
  CHARACTER: "CHARACTER",
});

export const loadingTextOptions = [
  "Forging Epic Adventures...",
  "Summoning Mythical Realms...",
  "Unleashing Imagination...",
  "Crafting Heroic Tales...",
  "Venturing into Unknown Depths...",
  "Unraveling Ancient Mysteries...",
  "Forging Alliances and Enemies...",
  "Embarking on Quests of Valor...",
  "Confronting Legendary Creatures...",
  "Delving into Treacherous Dungeons...",
  "Mastering Powerful Spells...",
  "Navigating Labyrinthine Mazes...",
  "Discovering Hidden Loot and Riches...",
  "Defending Kingdoms from Darkness...",
  "Creating Heroes and Legends...",
];
