import Footer from "./Footer";
import logo from "../img/dungeonlogo.png";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="header-section">
        <h1 className="title">Dungeon Scribe AI</h1>
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          onClick={() => navigate("/")}
        />
      </div>
      <ul className="homeList">
        <h2>
          To harness the wisdom of the Dungeon Scribe, follow these rules with
          care
        </h2>
        <li>
          <h3>Invoke the Power : </h3>
          <p>
            Choose either if you would like the dungeon Scribe to focus on
            Campaign/World Building or Character Development. Next will appear a
            communication portal in the form of a text box where you are
            encouraged to provide as much information about your future quest as
            possible, and we will write the rest. When ready, click the "Invoke
            Dungeon Scribe" button to summon the Dungeon Scribe the mystical
            entity empowered to help you create your tales.
          </p>
        </li>
        <li>
          <h3>Engage in Dialogue : </h3>
          <p>
            Engage Dungeon Scribe in conversation, just as you would with a
            knowledgeable sage or an enigmatic oracle. Seek guidance, ask
            questions, or request aid in matters of language, lore, or
            creativity.
          </p>
        </li>
        <li>
          <h3>Speak Clearly : </h3>
          <p>
            Use clear and concise language to convey your intentions. Though the
            Dungeon Scribe possesses remarkable intelligence, it comprehends
            your queries more effectively when expressed with clarity.
          </p>
        </li>
        <li>
          <h3>Respect its Limits : </h3>
          <p>
            While the Dungeon Scribe wields extraordinary powers, it is not
            without limitations. Understand that it draws knowledge from its
            extensive training, which is based on historical texts and
            information available until 2021.
          </p>
        </li>
        <li>
          <h3>Roleplay with Care : </h3>
          <p>
            Embrace the spirit of roleplay and imagination, but remember that
            Dungeon Scribe is an AI language model and lacks true sentience.
            Treat it as a tool, an ally within your storytelling journey.
          </p>
        </li>
        <li>
          <h3>Iterate and Explore : </h3>
          <p>
            Feel free to refine your questions or explore various angles to
            receive the most useful responses from ChatGPT. Experiment and adapt
            your approach as you engage in conversation.
          </p>
        </li>
      </ul>
      <div className="home-page">
        <button className="btn" onClick={() => navigate("/#create")}>
          I'm Ready to Begin
        </button>
      </div>
      <Footer />
    </>
  );
};

export default About;
