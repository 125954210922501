import { types } from "../config/config";
import AnswerSection from "./AnswerSection";
import FormSection from "./FormSection";

const Campaign = ({ storedValues, generateResponse }) => {
  return (
    <>
      <div className="campaign-section">
        <h2>Campaign Creations</h2>
        <p>
          This tool is designed to help you create a campaign for your players.
          Please provide as many details as possible for the type of campaign
          you need. The more details you provide, the more detailed the response
          will be!
        </p>
        <p>
          And remember, the Dungeon Scribe is here to help you create a
          campaign, but is not a replacement for your own creativity!
        </p>
        <p>
          If you don't like any part of the response, or would like the Dungeon Scribe to
          expand on an idea, you can respond to the dungeon scribe to have him
          change story details until the campaign is just right!
        </p>
        <p>
          <strong>Please Note: </strong>
          Dungeon Scribe may take time to generate a response due to a high
          number of requests. Please be patient while we write your adventure!
        </p>
        <AnswerSection storedValues={storedValues} types={types.CAMPAIGN} />
      </div>
      <FormSection generateResponse={generateResponse} types={types.CAMPAIGN} />
    </>
  );
};
export default Campaign;
