import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import { loadingTextOptions } from "../config/config";
import logo from "../img/dungeonlogo.png";

const LoadingScreen = ({ show, setShow }) => {
  const [text, setText] = useState(loadingTextOptions[0]);
  const [index, setIndex] = useState(0);
  const [longWaitText, setLongWaitText] = useState(false);
  useEffect(() => {
    let intervalId;

    if (show) {
      intervalId = setInterval(() => {
        setText(loadingTextOptions[index]);
        setIndex((prevIndex) => (prevIndex + 1) % loadingTextOptions.length);
      }, 3000);

      setTimeout(() => {
        setLongWaitText(true);
      }, 20000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [show, loadingTextOptions, index]);

  return (
    <>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Body className="loading-modal">
          <img src={logo} className="loader"></img>
          <Card>
            <Card.Body>
              <Card.Text className="loading-text">{text}</Card.Text>
              {longWaitText && (
                <Card.Text className="loading-tex-long">
                  The Dungeon Scribe is still working hard on your request
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoadingScreen;
