import { types } from "../config/config";
import AnswerSection from "./AnswerSection";
import FormSection from "./FormSection";

const Character = ({ storedValues, generateResponse }) => {
  console.log(storedValues)
  return (
    <>
      <div className="character-section">
        <h2>Character Development</h2>
        <p>
          This tool is designed to help you create a character sheet and
          backstory story for your character. Please provide as many details as
          possible for the type of character you need. The more details you
          provide, the more detailed the response will be!
        </p>
        <p>
          And remember, the Dungeon Scribe is here to help you create a
          campaign, but is not a replacement for your own creativity!
        </p>
        <p>
          If you don't like any part of the response, or would like the Dungeon
          Scribe to expand on an idea, you can respond to the dungeon scribe to
          have him change story details until the campaign is just right!
        </p>
        <p>
          <strong>Please Note: </strong>
          Dungeon Scribe may take time to generate a response due to a high
          number of requests. Please be patient while we create your characters!
        </p>
        <AnswerSection storedValues={storedValues} types={types.CHARACTER} />
      </div>
      <FormSection
        generateResponse={generateResponse}
        types={types.CHARACTER}
      />
    </>
  );
};
export default Character;
