import { useState } from "react";

const FormSection = ({ generateResponse, types }) => {
  const [newQuestion, setNewQuestion] = useState("");


  return (
    <div className="form-section">
      <textarea
        rows="5"
        className="form-control"
        placeholder="What is your quest
          ...and what is your favorite color?
          ...and what is the airspeed velocity of an unladen swallow?
          
          Give us any other details you think might be helpful When generating the story!"
        value={newQuestion}
        onChange={(e) => setNewQuestion(e.target.value)}
      ></textarea>
      <button
        className="btn"
        onClick={() => generateResponse(newQuestion, setNewQuestion, types)}
      >
        Invoke the Dungeon Scribe
      </button>
    </div>
  );
};

export default FormSection;
