import Footer from "./components/Footer";
import logo from "./img/dungeonlogo.png";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="header-section">
        <h1 className="title">Dungeon Scribe AI</h1>
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="privacy">
        <h1>Privacy Policy for Dungeon Scribe AI</h1>
        <strong>Effective Date: May 25, 2023</strong>
        <p>
          At Dungeon Scribe, we value your privacy and are committed to
          protecting your personal information. This Privacy Policy explains how
          we collect, use, and disclose information when you use our services,
          including the use of cookies and other tracking technologies. By using
          Dungeon Scribe, you consent to the practices described in this Privacy
          Policy. Information We Collect We collect certain information when you
          use Dungeon Scribe, including: a. Personal Information: We may collect
          personal information such as your name, email address, and payment
          details when you sign up for an AdSense account. b. Usage Information:
          We collect information about your interactions with our services,
          including your IP address, device information, and browsing behavior.
          c. Cookies and Similar Technologies: We use cookies and similar
          technologies to collect information about your activities on our
          website. These technologies help us personalize your experience,
          analyze trends, and deliver targeted advertisements. Use of
          Information We use the collected information for the following
          purposes: a. Providing and Improving Services: We use your information
          to provide, maintain, and improve our services, including displaying
          relevant advertisements based on your interests. b. Analytics and
          Research: We analyze the data to understand trends, improve user
          experience, and optimize our advertising campaigns. c.
          Personalization: We may use the collected information to personalize
          the advertisements you see on our network based on your interests,
          preferences, and browsing behavior. d. Communication: We may send you
          administrative emails, updates, and promotional messages related to
          our services. Data Sharing We may share your information with the
          following parties: a. Affiliates and Service Providers: We may share
          your information with our affiliated companies and trusted service
          providers who assist us in providing and improving our services. b.
          Advertising Partners: We may share information with our advertising
          partners to deliver targeted advertisements and measure the
          effectiveness of advertising campaigns. c. Legal Compliance: We may
          disclose your information to comply with applicable laws, regulations,
          legal processes, or governmental requests. GDPR Consent Message In
          accordance with the General Data Protection Regulation (GDPR), we
          obtain your consent for the collection and use of personal information
          through a GDPR consent message. This message will inform you about the
          types of information we collect, the purposes of processing, and your
          rights regarding your personal data. Your Rights Under the GDPR and
          applicable data protection laws, you have certain rights regarding
          your personal information. These rights may include the right to
          access, rectify, restrict processing, object to processing, and delete
          your data. You can exercise these rights by contacting us using the
          information provided in Section 7. Security We take reasonable
          measures to protect your personal information from unauthorized
          access, disclosure, alteration, or destruction. However, please be
          aware that no security measure is perfect, and we cannot guarantee the
          absolute security of your information. Contact Us If you have any
          questions or concerns regarding this Privacy Policy or the way we
          handle your personal information, please contact us at: [Insert
          contact details: address, email, phone] We will respond to your
          inquiries and address your concerns in a timely manner. Changes to the
          Privacy Policy We may update this Privacy Policy from time to time. We
          will notify you of any material changes by posting the revised policy
          on our website or through other means. Please review this Privacy
          Policy periodically to stay informed about our data practices. By
          continuing to use Dungeon Scribe after the effective date of any
          changes to this Privacy Policy, you agree to the revised terms.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
