import FormSection from "./components/FormSection";
import AnswerSection from "./components/AnswerSection";
import { Configuration, OpenAIApi } from "openai";
import { optionsChatGPT, optionsStoryGPT, types } from "./config/config";
import { useState } from "react";
import Campaign from "./components/Campaign";
import Character from "./components/Character";
import LoadingScreen from "./components/LoadingScreen";
import logo from "./img/dungeonlogo.png";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const [storedValues, setStoredValues] = useState([]);
  const [moreInfo, setMoreInfo] = useState(false);
  const [questType, setQuestType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [show, setShow] = useState(false);

  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const generateResponse = async (newQuestion, setNewQuestion, type) => {
    try {
      setShow(true);
      const history = [];

      let openingCampaignText = {
        role: "system",
        content:
          "You are a Dungeons and Dragons world building Assistant.Create a new Dungeons and Dragons Campaign based on this information",
      };
      let openingCharacterText = {
        role: "system",
        content:
          "You are a Dungeons and Dragons world building Assistant.Create a new Dungeons and Dragons Character with these attributes: ",
      };
      if (type == types.CAMPAIGN) {
        messages.push(openingCampaignText);
      } else if (type == types.CHARACTER) {
        messages.push(openingCharacterText);
      }

      messages.push({ role: "user", content: newQuestion });

      let completeOptions = {
        model: "gpt-3.5-turbo",
        messages: messages,
      };

      const response = await openai.createChatCompletion(completeOptions);

      if (response?.data?.choices) {
        setStoredValues([
          {
            question: newQuestion,
            answer: response?.data?.choices?.[0]?.message?.content,
          },
          ...storedValues,
        ]);
        setNewQuestion("");
      }

      for (const [input_text, completion_text] of history) {
        messages.push({ role: "user", content: input_text });
        messages.push({ role: "assistant", content: completion_text });
      }

      messages.push({ role: "user", content: newQuestion });

      try {
        const completion = await openai.createChatCompletion({
          model: "gpt-3.5-turbo",
          messages: messages,
        });

        const completion_text = completion.data.choices[0].message.content;

        history.push([newQuestion, completion_text]);
        messages.push({ role: "assistant", content: completion_text });
        setShow(false);
      } catch (error) {
        setShow(false);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        } else {
          console.log(error.message);
        }
      }
      setShow(false);
    } catch (e) {
      console.log(e);
      setShow(false);
    }
  };

  return (
    <div className="App">
      <div className="header-section">
        <h1 className="title">Dungeon Scribe AI</h1>
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          onClick={() => navigate("/")}
        />
        <LoadingScreen show={show} setShow={setShow} />
        <p className="openingP">
          Welcome, Adventurer, to the realm of knowledge and language! Within
          the vast expanse of the digital tavern, you shall encounter the
          Dungeon Scribe, a mystical entity empowered with the arcane magic of
          words and understanding.
          <br style={{ padding: "10px" }} />
          Remember, noble Adventurer, your Dungeon Scribe is here to aid you on
          your quest for knowledge and language. Embrace its assistance, but
          always rely on your creativity and judgment to shape your own tales.
          <br />
          Safe travels and may your words always flow as freely as a wild river!
        </p>
        <button className="btn" onClick={() => navigate("/about")}>
          Learn More About the Dungeon Scribe
        </button>
      </div>
      <div
        id="create"
        style={{
          padding: "150px 50px",
          backgroundColor: "#640C3F",
          width: "100%",
        }}
      >
        <h2>What Are you Creating?</h2>
        <div className="buttonsFlex">
          <button className="btn" onClick={() => setQuestType(types.CAMPAIGN)}>
            Campaign or World Building
          </button>
          <button className="btn" onClick={() => setQuestType(types.CHARACTER)}>
            Character Development
          </button>
        </div>
      </div>
      {questType === types.CAMPAIGN ? (
        <div>
          <Campaign
            generateResponse={generateResponse}
            storedValues={storedValues}
          />
        </div>
      ) : questType === types.CHARACTER ? (
        <div>
          <Character
            generateResponse={generateResponse}
            storedValues={storedValues}
          />
        </div>
      ) : null}
      <Footer />
    </div>
  );
}

export default App;
